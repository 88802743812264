import react from 'react'

import AppR3f from './AppR3f'
import {Overlay} from './Overlay'
export default function LandingPageAppR3f() {
    return (
        <>
            <AppR3f />
            <Overlay />
        </>)
}