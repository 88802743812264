export function Overlay() {

  function sendEmail() {
    window.location = "mailto:dh@picme.click";
  }
  return (
    <>
      <div className="char" style={{ top: '10vh', left: "55vw" }}>PIC</div>

      <div className="char" style={{ top: '45vh', left: '15vw' }}>
        ME
      </div>

      <div className="char" style={{ bottom: '10vh', left: '55vw' }}>
        CLICK
      </div>
      <div style={{ position: 'absolute', top: 40, right: 160, fontSize: '15px', textAlign: 'right', color: 'white' }} onClick={sendEmail}>
        dh@picme.click
        <br />

      </div>
      <div style={{ position: 'absolute', top: 40, right: 40, fontSize: '15px', textAlign: 'right', fontVariantNumeric: 'tabular-nums', color: 'white' }}>
        Portfolio
        <br />

      </div>
      <svg style={{ position: 'absolute', right: 40, top: '50%' }} width="54" height="23" viewBox="0 0 54 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line y1="1.5" x2="54" y2="1.5" stroke="white" strokeWidth="3" />
        <line y1="11.5" x2="54" y2="11.5" stroke="white" strokeWidth="3" />
        <line y1="21.5" x2="54" y2="21.5" stroke="white" strokeWidth="3" />
      </svg>
      <div style={{ position: 'absolute', bottom: 120, left: 120, fontSize: '18px', color: 'white' }}>
        AR/VR Development
        <br />
        Web, iOS, Meta
        <br />
        <br />
        <div style={{ position: 'relative', marginTop: 10, display: 'inline-block' }}>
          <a style={{ fontSize: '15px', fontWeight: 600, letterSpacing: 2 }} href="https://picme.click">
            INFORMATION
          </a>
          <div style={{ marginTop: 6, height: 2.5, width: '100%', background: 'white' }} />
        </div>
        <br />
      </div>
    </>
  )
}
